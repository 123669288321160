.conteneur {
  min-width: 100%;
}

/* === conteneur gauche ===  */

.conteneur-gauche {
  background-color: #f4f4f4;
  position: absolute;
  top: 56.781px;
  left: 0;
  bottom: 0;
  width: 20%;
  height: 120%;
  transition: all 0.5s ease;
}

.conteneur-gauche.fermer {
  width: 6%;
}

@media (max-width: 920px) {
  .conteneur-gauche.fermer {
    width: 9%;
  }
}

@media (max-width: 638px) {
  .conteneur-gauche {
    top: 96.781px;
  }
}

@media (max-width: 566px) {
  .conteneur-gauche {
    top: 136.781px;
  }
}

.enveloppe {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 541px) {
  .conteneur-gauche {
    display: none;
  }
}

@media ((min-width: 541px) and (max-width: 919px)) {
  .conteneur-gauche {
    width: 30%;
  }
}

.zone-titre {
  transition: all 0.5s ease;
}

.titre {
  font-size: 1.3rem;
  font-weight: 500;
  color: #555;
}

.sous-titre {
  font-style: italic;
  color: #555;
}

.icone-titre {
  display: none;
}

.icone-titre.hide-element {
  display: flex;
  margin-top: 1.59rem;
}

.reduire-sidebar {
  position: absolute;
  top: 2.5rem;
  right: -0.8rem;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #e8e8e8;
  cursor: pointer;
}

.etendre-sidebar {
  position: absolute;
  top: 2.5rem;
  right: -0.8rem;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #e8e8e8;
  cursor: pointer;
}

.zone-element {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.zone-element.hide-element {
  margin-top: 2.2rem;
}

.element {
  display: flex;
  align-items: center;
  padding: 5% 1rem;
  border-radius: 4px;
  transition: all 0.5s ease;
  color: #555;
}

.element-picture {
  margin-right: 0.5rem;
}

.element-text {
  font-size: 1.2rem;
  margin-left: 0.6rem;
}

.element:hover {
  background-color: #b7b7b7;
  cursor: pointer;
  color: #f4f4f4;
}

.zone-titre.hide-element .titre {
  display: none;
}

.zone-titre.hide-element .sous-titre {
  display: none;
}

.element.hide-element {
  padding: 0.4rem 0.7rem 0.4rem 1rem;
  margin: 0.6rem 0;
}

.element.hide-element .element-text {
  display: none;
}

.zone-deconnexion {
  margin-top: 1.5rem;
}

.zone-deconnexion.hide-element .ligne-deconnexion {
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
}

.zone-deconnexion.hide-element .element-deconnexion .element-text {
  display: none;
}

.element-deconnexion {
  display: flex;
  align-items: center;
  padding: 5% 1rem;
  transition: all 0.5s ease;
}

.element-deconnexion img {
  cursor: pointer;
}

.ligne-deconnexion {
  background-color: #333;
}

.zone-deconnexion .element-deconnexion .element-text {
  cursor: pointer;
}

.zone-deconnexion .element-deconnexion .element-text:hover {
  color: #f27878;
}

/* === conteneur droit ===  */

.conteneur-droit {
  margin-left: 180px;
  width: 85%;
  height: 100vh;
  /* display: flex; */
  transition: all 0.5s ease;
}

.conteneur-droit.fermer {
  width: 105%;
  margin-left: 0;
}

@media (max-width: 540px) {
  .conteneur-droit {
    margin-left: 0;
  }
}

@media ((min-width: 541px) and (max-width: 919px)) {
  .conteneur-droit {
    width: 75%;
  }
}

@media ((min-width: 768px)) {
  .conteneur-droit {
    width: 75%;
    margin-left: 27%;
  }
  .conteneur-droit.fermer {
    width: 85%;
    margin-left: 5%;
  }
}

/* dashboard menu mobile  */

.menu-mobile {
  position: relative;
  left: 3.45%;
}

.conteneur-gauche-mobile {
  background-color: #f4f4f4;
  position: absolute;
  top: 52.78px;
  left: 0;
  width: 0rem;
  height: 120%;
}

.conteneur-gauche-mobile.ouvrir-mobile {
  transition: all 0.5s ease;
  width: 55%;
}

.conteneur-gauche-mobile.fermer-mobile {
  width: 0.000000001%;
  transition: all 0.5s ease;
}

.enveloppe-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}

@media (min-width: 541px) {
  .menu-mobile {
    display: none;
  }
}

@media ((min-width: 390px) and (max-width: 560px)) {
  .conteneur-gauche-mobile {
    top: 58px;
  }
}

@media (max-width: 541px) {
  .enveloppe-mobile.ouvrir-mobile {
    display: block;
  }
}

@media ((min-width: 311px) and (max-width: 382px)) {
  .conteneur-gauche-mobile {
    top: 97.78px;
  }
}

@media (max-width: 310px) {
  .conteneur-gauche-mobile.ouvrir-mobile {
    top: 137.78px;
    width: 72%;
  }
}
