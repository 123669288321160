body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
}

.header-padding {
  padding: 0.4rem 10rem;
}

@media (max-width: 540px) {
  .header-padding {
    padding: 0.4rem 2rem;
  }

  .barre-recherche {
    margin-top: 0.6rem;
  }
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1 1;
}

.featured-message {
  font-size: 2.3rem;
  color: #444;
  text-align: left;
  margin: 3rem auto;
}

@media (max-width: 540px) {
  .featured-message {
    text-align: center;
  }
}
.btn-primary,
.btn-outline-primary {
  background-color: #b2f040;
  border-color: #b2f040;
  color: #f5f5f5;
  /* background-color: #f0c040; */
  /* color: #000000; */
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #f0c040;
  border-color: #f0c040;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  background-color: #edb620;
  border-color: #edb620;
}

.produits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.produit {
  border: 1px #404040 solid;
  margin: 1rem;
}

.produit img {
  width: 100%;
  max-width: 400px;
}

.produit-info {
  padding: 1rem;
}

.note span {
  color: #ffc000;
}

.img-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

.vert {
  color: green;
}

.rouge {
  color: red;
}

.bg-green {
  background-color: #b2f040;
}

.jaune {
  color: yellow;
}

.etapes-caisse > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.etapes-caisse > div.actif {
  border-bottom: 0.2rem solid #b2f040;
  color: #9ed439;
}

/* barre latérale */
.navbar-brand {
  font-weight: bold;
}

.navbar-laterale {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}

.cont-actif {
  margin-left: 240px;
}

.nav-actif {
  left: 0;
}

.nav-link {
  color: #ccc;
}

.nav-link:hover {
  color: #f0c040;
}

.text-bold {
  font-weight: bold;
}

.card-img-top {
  max-width: 200px;
  max-height: 200px;
}

.flutterwave-button {
  padding: 0.55rem;
  background-color: #f0c040;
  border: none;
  border-color: #f0c040;
  border-radius: 10px;
  color: #fff;
}

.flutterwave-button:hover {
  background-color: #f08000;
}

.banner {
}

.banner-container {
  display: flex;
  align-items: center;

  height: 22rem;
  padding: 2rem;
}

/* All about banner-button */
.bannerButton-container {
  margin-top: 2rem;
  width: 16rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bannerButton {
  font-size: 1.1rem;
  height: 2rem;
  border-radius: 0;
  border: 0;
  cursor: pointer;
}

.left {
  border: 0.15rem solid #f9f9f9;
  background: transparent;
  padding: 0 1rem;
  color: #f9f9f9;
}

.left:hover {
  border: 0.15rem solid #f9f9f9;
  background: #fff;
  padding: 0 1rem;
  color: #999;
}

.right {
  background-color: #f9f9f9;
  padding: 0 1rem;
  color: #fff;
  background-color: #42b2fd;
}

.right:hover {
  background-color: #f9f9f9;
  padding: 0 1rem;
  color: #fff;
  background-color: #50abe7;
}

@media (max-width: 375px) {
  .bannerButton-container {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .bannerButton {
    height: 2rem;
    padding: 0.5rem;
    border-radius: 0;
    border: 0;
    cursor: pointer;
  }

  .left {
    border: 0.15rem solid #f9f9f9;
    background: transparent;
    color: #f9f9f9;
  }

  .right {
    background-color: #f9f9f9;
    margin-top: 1rem;
  }
}

/* All about banner-text */

.bannerText-container {
  font-weight: bold;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  color: #f9f9f9;
}

.bannerText-container h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.bannerText-container-1 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #f9f9f9;
  margin-bottom: 0.5rem;
}

.bannerText-container-1 h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #f0c040;
}

@media (max-width: 820px) {
  .bannerText-container h2 {
    color: #ffe291;
    /* color: #f0c040; */
  }
}
.bannerText-container h3 {
  font-size: 1.7rem;
  font-weight: bold;
}
.bannerText-container h4 {
  font-size: 1.3rem;
  font-weight: bold;
}

@media (max-width: 375) {
  .bannerText-container {
    /* margin-top: 9rem; */
  }
}

.banner-fadeBottom {
  height: 10rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.21),
    #ccc
  );
}

.conteneur {
  min-width: 100%;
}

/* === conteneur gauche ===  */

.conteneur-gauche {
  background-color: #f4f4f4;
  position: absolute;
  top: 56.781px;
  left: 0;
  bottom: 0;
  width: 20%;
  height: 120%;
  transition: all 0.5s ease;
}

.conteneur-gauche.fermer {
  width: 6%;
}

@media (max-width: 920px) {
  .conteneur-gauche.fermer {
    width: 9%;
  }
}

@media (max-width: 638px) {
  .conteneur-gauche {
    top: 96.781px;
  }
}

@media (max-width: 566px) {
  .conteneur-gauche {
    top: 136.781px;
  }
}

.enveloppe {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 541px) {
  .conteneur-gauche {
    display: none;
  }
}

@media ((min-width: 541px) and (max-width: 919px)) {
  .conteneur-gauche {
    width: 30%;
  }
}

.zone-titre {
  transition: all 0.5s ease;
}

.titre {
  font-size: 1.3rem;
  font-weight: 500;
  color: #555;
}

.sous-titre {
  font-style: italic;
  color: #555;
}

.icone-titre {
  display: none;
}

.icone-titre.hide-element {
  display: flex;
  margin-top: 1.59rem;
}

.reduire-sidebar {
  position: absolute;
  top: 2.5rem;
  right: -0.8rem;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #e8e8e8;
  cursor: pointer;
}

.etendre-sidebar {
  position: absolute;
  top: 2.5rem;
  right: -0.8rem;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #e8e8e8;
  cursor: pointer;
}

.zone-element {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.zone-element.hide-element {
  margin-top: 2.2rem;
}

.element {
  display: flex;
  align-items: center;
  padding: 5% 1rem;
  border-radius: 4px;
  transition: all 0.5s ease;
  color: #555;
}

.element-picture {
  margin-right: 0.5rem;
}

.element-text {
  font-size: 1.2rem;
  margin-left: 0.6rem;
}

.element:hover {
  background-color: #b7b7b7;
  cursor: pointer;
  color: #f4f4f4;
}

.zone-titre.hide-element .titre {
  display: none;
}

.zone-titre.hide-element .sous-titre {
  display: none;
}

.element.hide-element {
  padding: 0.4rem 0.7rem 0.4rem 1rem;
  margin: 0.6rem 0;
}

.element.hide-element .element-text {
  display: none;
}

.zone-deconnexion {
  margin-top: 1.5rem;
}

.zone-deconnexion.hide-element .ligne-deconnexion {
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
}

.zone-deconnexion.hide-element .element-deconnexion .element-text {
  display: none;
}

.element-deconnexion {
  display: flex;
  align-items: center;
  padding: 5% 1rem;
  transition: all 0.5s ease;
}

.element-deconnexion img {
  cursor: pointer;
}

.ligne-deconnexion {
  background-color: #333;
}

.zone-deconnexion .element-deconnexion .element-text {
  cursor: pointer;
}

.zone-deconnexion .element-deconnexion .element-text:hover {
  color: #f27878;
}

/* === conteneur droit ===  */

.conteneur-droit {
  margin-left: 180px;
  width: 85%;
  height: 100vh;
  /* display: flex; */
  transition: all 0.5s ease;
}

.conteneur-droit.fermer {
  width: 105%;
  margin-left: 0;
}

@media (max-width: 540px) {
  .conteneur-droit {
    margin-left: 0;
  }
}

@media ((min-width: 541px) and (max-width: 919px)) {
  .conteneur-droit {
    width: 75%;
  }
}

@media ((min-width: 768px)) {
  .conteneur-droit {
    width: 75%;
    margin-left: 27%;
  }
  .conteneur-droit.fermer {
    width: 85%;
    margin-left: 5%;
  }
}

/* dashboard menu mobile  */

.menu-mobile {
  position: relative;
  left: 3.45%;
}

.conteneur-gauche-mobile {
  background-color: #f4f4f4;
  position: absolute;
  top: 52.78px;
  left: 0;
  width: 0rem;
  height: 120%;
}

.conteneur-gauche-mobile.ouvrir-mobile {
  transition: all 0.5s ease;
  width: 55%;
}

.conteneur-gauche-mobile.fermer-mobile {
  width: 0.000000001%;
  transition: all 0.5s ease;
}

.enveloppe-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}

@media (min-width: 541px) {
  .menu-mobile {
    display: none;
  }
}

@media ((min-width: 390px) and (max-width: 560px)) {
  .conteneur-gauche-mobile {
    top: 58px;
  }
}

@media (max-width: 541px) {
  .enveloppe-mobile.ouvrir-mobile {
    display: block;
  }
}

@media ((min-width: 311px) and (max-width: 382px)) {
  .conteneur-gauche-mobile {
    top: 97.78px;
  }
}

@media (max-width: 310px) {
  .conteneur-gauche-mobile.ouvrir-mobile {
    top: 137.78px;
    width: 72%;
  }
}

