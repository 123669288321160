body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
}

.header-padding {
  padding: 0.4rem 10rem;
}

@media (max-width: 540px) {
  .header-padding {
    padding: 0.4rem 2rem;
  }

  .barre-recherche {
    margin-top: 0.6rem;
  }
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.featured-message {
  font-size: 2.3rem;
  color: #444;
  text-align: left;
  margin: 3rem auto;
}

@media (max-width: 540px) {
  .featured-message {
    text-align: center;
  }
}
.btn-primary,
.btn-outline-primary {
  background-color: #b2f040;
  border-color: #b2f040;
  color: #f5f5f5;
  /* background-color: #f0c040; */
  /* color: #000000; */
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #f0c040;
  border-color: #f0c040;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  background-color: #edb620;
  border-color: #edb620;
}

.produits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.produit {
  border: 1px #404040 solid;
  margin: 1rem;
}

.produit img {
  width: 100%;
  max-width: 400px;
}

.produit-info {
  padding: 1rem;
}

.note span {
  color: #ffc000;
}

.img-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

.vert {
  color: green;
}

.rouge {
  color: red;
}

.bg-green {
  background-color: #b2f040;
}

.jaune {
  color: yellow;
}

.etapes-caisse > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.etapes-caisse > div.actif {
  border-bottom: 0.2rem solid #b2f040;
  color: #9ed439;
}

/* barre latérale */
.navbar-brand {
  font-weight: bold;
}

.navbar-laterale {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}

.cont-actif {
  margin-left: 240px;
}

.nav-actif {
  left: 0;
}

.nav-link {
  color: #ccc;
}

.nav-link:hover {
  color: #f0c040;
}

.text-bold {
  font-weight: bold;
}

.card-img-top {
  max-width: 200px;
  max-height: 200px;
}

.flutterwave-button {
  padding: 0.55rem;
  background-color: #f0c040;
  border: none;
  border-color: #f0c040;
  border-radius: 10px;
  color: #fff;
}

.flutterwave-button:hover {
  background-color: #f08000;
}
