.banner {
}

.banner-container {
  display: flex;
  align-items: center;

  height: 22rem;
  padding: 2rem;
}

/* All about banner-button */
.bannerButton-container {
  margin-top: 2rem;
  width: 16rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bannerButton {
  font-size: 1.1rem;
  height: 2rem;
  border-radius: 0;
  border: 0;
  cursor: pointer;
}

.left {
  border: 0.15rem solid #f9f9f9;
  background: transparent;
  padding: 0 1rem;
  color: #f9f9f9;
}

.left:hover {
  border: 0.15rem solid #f9f9f9;
  background: #fff;
  padding: 0 1rem;
  color: #999;
}

.right {
  background-color: #f9f9f9;
  padding: 0 1rem;
  color: #fff;
  background-color: #42b2fd;
}

.right:hover {
  background-color: #f9f9f9;
  padding: 0 1rem;
  color: #fff;
  background-color: #50abe7;
}

@media (max-width: 375px) {
  .bannerButton-container {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .bannerButton {
    height: 2rem;
    padding: 0.5rem;
    border-radius: 0;
    border: 0;
    cursor: pointer;
  }

  .left {
    border: 0.15rem solid #f9f9f9;
    background: transparent;
    color: #f9f9f9;
  }

  .right {
    background-color: #f9f9f9;
    margin-top: 1rem;
  }
}

/* All about banner-text */

.bannerText-container {
  font-weight: bold;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  color: #f9f9f9;
}

.bannerText-container h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.bannerText-container-1 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #f9f9f9;
  margin-bottom: 0.5rem;
}

.bannerText-container-1 h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #f0c040;
}

@media (max-width: 820px) {
  .bannerText-container h2 {
    color: #ffe291;
    /* color: #f0c040; */
  }
}
.bannerText-container h3 {
  font-size: 1.7rem;
  font-weight: bold;
}
.bannerText-container h4 {
  font-size: 1.3rem;
  font-weight: bold;
}

@media (max-width: 375) {
  .bannerText-container {
    /* margin-top: 9rem; */
  }
}

.banner-fadeBottom {
  height: 10rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.21),
    #ccc
  );
}
